import { template as template_9df83132617846e38da4306dc7658e48 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9df83132617846e38da4306dc7658e48(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
