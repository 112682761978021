import { template as template_8ab924fd2d7e4b69afa09708d996f465 } from "@ember/template-compiler";
const FKText = template_8ab924fd2d7e4b69afa09708d996f465(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
